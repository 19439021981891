<template>
        <div class="flex justify-center">
            <div class="col-5 w-5/12 mt-5">
                <div class="rounded-xl bg-white drop-shadow-xl pl-5 pr-5 py-5 border 
                    border-slate-200shadow-right shadow-lg shadow-gray-500">
                    <div class='row'>
                        <div class='column'>
                            <form >
                                <div class="grid md:grid-cols-1">
                                    <div class="relative z-0 w-full mb-6 group">
                                        <input type="text" v-model="firstname" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent border-b-2 shadow-xl rounded-3xl
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100 
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" placeholder="Firstname" />
                                    </div>
                                    <div class="relative z-0 w-full mb-6 group">
                                        <input type="text" v-model="lastname" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent shadow-xl rounded-3xl border-b-2 
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100 
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" placeholder="Lastname" />
                                    </div>
                                </div>
                                <div class="grid md:grid-cols-1">
                                    <div class="relative z-0 w-full mb-6 group">
                                        <input type="text" v-model="username" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent shadow-xl rounded-3xl border-b-2 
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100 
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" placeholder="Username" />
                                    </div>
                                    <div class="relative z-0 w-full mb-6 group">
                                        <input type="email" v-model="email" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent shadow-xl rounded-3xl border-b-2 
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100 
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" placeholder="Email" />
                                    </div>
                                </div>
                                <div class="grid md:grid-cols-1">
                                    <div class="relative z-0 w-full mb-6 group">
                                        <input type="password" v-model="password" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent shadow-xl rounded-3xl border-b-2 
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" placeholder="Password" />
                                    </div>
                                    <div class="relative z-0 w-full mb-6 group">
                                        <select v-model="role" class="block py-2.5 px-0 pl-5 w-full 
                                        text-sm text-gray-900 bg-transparent shadow-xl rounded-3xl border-b-2 
                                        border-gray-300 appearance-none dark:text-black hover:bg-gray-100
                                        dark:border-gray-600 dark:focus:border-blue-500 
                                        focus:outline-none focus:ring-0 focus:border-blue-600 
                                        peer" >
                                            <option value="" disabled selected>Choose Role</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Institution">Institution</option>
                                            <option value="Business">Business</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-5 flex justify-center">
                                    <button type="button" class="text-white border bg-blue-900 w-4/12 p-1.5 
                                    rounded-3xl font-semibold" @click="validation">
                                        ADD USER
                                    </button>
                                </div>
                                <span v-if="validationError" class="text-sm flex justify-center"
                                    :class="{ 'error-message': validationError, 'error-text-red': validationError }">
                                    {{ errorMessage }}</span>
                                <span v-if="validationGood" class="text-sm flex justify-center"
                                    :class="{ 'good-message': validationGood, 'good-text-red': validationGood }">
                                    {{ goodMessage }}</span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg p-6 w-96 border shadow-lg shadow-green-900">
                <div class="flex justify-center">
                    <h2 class="text-xl font-semibold">
                        <i class="fa-regular fa-circle-check fa-2xl text-green-500"></i>
                        Registered Successful
                    </h2>
                </div>
                <div class="mt-4 flex justify-center">
                    <button @click="closeModal" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold
                         py-2 px-4 rounded">
                        Close
                    </button>
                </div>
            </div>
        </div>
</template> 

<script>
import Swal from 'sweetalert2'
import { CreateUser } from '../Graphql/Admin/register.graphql.js'
import { apolloClient } from '@/vue-apollo'

export default {
    name: 'RegisterPage',

    components: {
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            username: '',
            email: '',
            password: '',
            role: '',
            isModalOpen: false,
            validationError: false,
            errorMessage: '',
            validationGood: false,
            goodMessage: '',
        }
    },
    methods: {
        showSuccessAlert() {
        Swal.fire({
            title: 'Success!',
            text: 'successfully User Added',
            icon: 'success',
            confirmButtonText: 'OK',
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            willClose: () => {
              this.$router.push('/dashboard'); // Redirect to /dashboard after 2 seconds
            }
        });
        },
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        register() {
            let inputData = {
                firstName: this.username,
                lastName: this.lastname,
                email: this.email,
                username: this.username,
                password: this.password,
                role: this.role,
            }

            // console.log(this.firstname)
            apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: CreateUser,
                variables: {
                    inputData: inputData
                }
            }).then(response => {
                console.log(response);
                this.showSuccessAlert();
            }).catch(err => {
                console.log(err)
            })
        },
        validation() {
            const jina = /^[a-zA-Z]+$/;
            const email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
            const pwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,16}$/;

            if (this.firstname == "") {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Field firstname is empty!"
            }
            else if (this.lastname == "") {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Field lastname is empty!"
            }
            else if (this.username == "") {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Field username is empty!"
            }
            else if (this.email == "") {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Field email is empty!"
            }
            else if (this.password == "") {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Field password is empty!"
            }
            else if (!jina.test(this.firstname)) {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Only Alphabet at firstname input"
            }
            else if (!jina.test(this.lastname)) {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Only Alphabet at lastname input"
            }
            else if (!email.test(this.email)) {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "Invalid email, format: aa12@bb.cc"
            }
            else if (!pwd.test(this.password)) {
                this.validationError = true
                this.errorMessage = ""
                this.errorMessage = "password should be at least one uppercase lowercase at least one special character numbers length of 8 to 16 digits"
            }
            else {
                this.validationError = false
                this.validationGood = true
                this.errorMessage = " "
                this.goodMessage = "Valid"
                this.goodMessage = ""
                this.register()
            }
        },
    },

}
</script>
 
<style scoped>
.error-message {
    color: red;
}

.error-text-red {
    color: red;
}

.good-message {
    color: green;
}

.good-text-red {
    color: green;
}
</style>