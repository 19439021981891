<template>
  <div class="leading-normal tracking-normal h-screen">
    <div class="flex justify-center g-0  h-screen">

      <SidebarVue />
      
      <div class="bg-white pl-0 lg:pl-64 w-full" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

        <NavbarVue class="border-b" />

        <div class="p-6 rounded-xl">
          <div>
            <RouterView/>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import SidebarVue from '@/components/Sidebar.vue';
import NavbarVue from '@/components/Navbar.vue';


export default {
  name: 'AdminVue',

  components: {
    SidebarVue,
    NavbarVue,
 },
}

</script>

<style>
.bg-color {
    background: linear-gradient(330deg, #020024, #28409a, #00d4ff);
    background-size: 600% 600%;

    -webkit-animation: bg-animation 18s ease infinite;
    -moz-animation: bg-animation 18s ease infinite;
    animation: bg-animation 18s ease infinite;
}

@-webkit-keyframes bg-animation {
    0%{background-position:58% 0%}
    50%{background-position:43% 100%}
    100%{background-position:58% 0%}
}
@-moz-keyframes bg-animation {
    0%{background-position:58% 0%}
    50%{background-position:43% 100%}
    100%{background-position:58% 0%}
}
@keyframes bg-animation {
    0%{background-position:58% 0%}
    50%{background-position:43% 100%}
    100%{background-position:58% 0%}
}
@keyframes bg-animati{
    0%{background-position:58% 0%}
    50%{background-position:43% 100%}
    100%{background-position:58% 0%}
}
</style>