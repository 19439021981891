import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context';

import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { from } from "apollo-link";
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'https://cbdc.all.tz:4004/api',
})


const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers
    }
  }
});
const errorLink = onError(({ graphQLErrors, networkError,  operation, forward }) => {
  if (graphQLErrors){
    graphQLErrors.forEach(({ extensions }) => {
      if (Object.hasOwn(extensions?.exception, "message")) {
        const oldHeaders = operation.getContext().headers;

        operation.setContext({
          headers: {
            ...oldHeaders
          },
        });
        // Modify the operation context with a new token
        // Retry the request, returning the new observable
        return forward(operation);
      }
    });
  }
  if (networkError) {
    console.error(`Error : ${networkError}`)
  }
});
// Create the apollo client
export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({addTypename: false}),
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
