<template>
    <div class="space-y-24">
        <div class="grid grid-cols-3 gap-4">
            <div class="card border-b border-[#28409A] rounded-2xl shadow-md shadow-[#28409A] w-10/12 h-[3.5cm]">
                <h1 class="card-header text-center text-xl font-semibold text-[#FFC300]">Commerce banks connected</h1>
                <card class="card-body">
                    <div class="flex justify-center mt-5">
                        <div class="border h-12 w-12 flex items-center justify-center rounded-full
                     bg-[#FFC300]">
                            <i class="fa fa-building-columns fa-2xl text-white"></i>
                        </div>
                        <span class="m-3 text-xl">60,000</span>
                    </div>
                </card>
            </div>
            <div class="card border-b border-[#28409A] rounded-2xl shadow-md shadow-[#28409A] w-10/12 h-[3.5cm]">
                <h1 class="card-header text-center text-xl font-semibold text-[#31B53A]">Institutions connected</h1>
                <card class="card-body">
                    <div class="flex justify-center mt-5">
                        <div class="border h-12 w-12 flex items-center justify-center rounded-full
                     bg-[#31B53A]">
                            <i class="fa fa-building-columns fa-2xl text-white"></i>
                        </div>
                        <span class="m-3 text-xl">1000</span>
                    </div>
                </card>
            </div>
            <div class="card border-b border-[#28409A] rounded-2xl shadow-md shadow-[#28409A] w-10/12 h-[3.5cm]">
                <h1 class="card-header text-center text-xl font-semibold text-[#5F7CE2]">Businesses connected</h1>
                <card class="card-body">
                    <div class="flex justify-center mt-5">
                        <div class="border h-12 w-12 flex items-center justify-center rounded-full
                     bg-[#5F7CE2]">
                            <i class="fa fa-briefcase fa-2xl text-white"></i>
                        </div>
                        <span class="m-3 text-xl">3000</span>
                    </div>
                </card>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-2">
            <div class="card border-b border-[#28409A] rounded-2xl shadow-lg
             shadow-[#28409A] w-11/12">
                <h1 class="card-header text-center text-xl font-semibold
                 text-[#28409A]">Daily users</h1>
                <card class="card-body">
                    <div>
                        <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>
                    </div>
                </card>
            </div>
            <div class="card border-b border-[#28409A] rounded-2xl shadow-lg
             shadow-[#28409A] w-11/12">
                <h1 class="card-header text-center text-xl font-semibold
                 text-[#28409A]">New users</h1>
                <card class="card-body">
                    <div>
                        <apexchart width="500" type="line" :options="options" :series="series"></apexchart>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/free-regular-svg-icons'
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/vue-fontawesome';
import VueApexCharts from 'vue3-apexcharts'
import 'apexcharts'
export default {
    name: 'HomeVue',
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
        options: {
            chart: {
            id: 'vuechart-example'
            },
            xaxis: {
            categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            },
            
        },
        series: [{
            data: [30, 40, 45, 50, 49, 60, 70]
        }]
        }
    },
};
</script>

<style></style>