import {gql} from "graphql-tag";

export const CreateUser = gql `
mutation CreateUser($inputData:UserCredentialType!){
  createUser(
    userDetails: $inputData){
    user{
      id
      firstName
      lastName
      email
      username
      password
      Role
    }
  }
}
`