<template>
    <div class="sticky top-0 z-40">
            <div class="w-full h-20 px-6 bg-white flex items-center justify-between">

              <!-- left navbar -->
              <div class="flex">

                <!-- mobile hamburger -->
                <div class="lg:hidden flex items-center mr-4 justify-end">
                  <button class="hover:text-blue-500 hover:border-white focus:outline-none 
                  navbar-burger" @click="toggleSidebar()">
                    <svg class="h-5 w-5" v-bind:style="{ fill: 'black' }" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                  </button>
                </div>
              </div>

              <!-- right navbar -->
              <div class="flex items-center relative space-x-5">
                    <div class="border h-10 w-10 flex items-center justify-center rounded-full
                     bg-[#5F7CE2]">
                        <i class="fa fa-bell fa-xl text-white"></i>
                    </div>
                    <div class="border h-10 w-10 flex items-center justify-center rounded-full
                     bg-[#5F7CE2]">
                        <i class="fa fa-user fa-xl text-white"  @click="dropDownOpen = !dropDownOpen"></i>
                    </div>
                </div>

            </div>

            <!-- dropdown menu -->
            <div class="absolute bg-gray-100 border flex border-t-0 shadow-xl text-gray-700 
            rounded-b-lg w-48 bottom-10 right-0 hover:bg-gray-200 mr-6" :class="dropDownOpen ? '' : 'hidden'">
            <i class="fa fa-right-from-bracket text-xl py-2 pl-5 text-blue-800"></i>
                <a href="#" class="block px-4 py-2 ">Logout</a>
            </div>
            <!-- dropdown menu end -->
      </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'OtherUserNavbarVue',
    computed: {
        ...mapState(['sideBarOpen'])
    },
    data() {
        return {
            dropDownOpen: false
        }
    },
    methods: {
        toggleSidebar() {
            this.$store.dispatch('toggleSidebar')
        }
    }
}
</script>