<template>
  <div class="absolute top-0 bg-color h-screen w-full">
    <div class="flex items-center justify-content-between flex-wrap p-4">
      <div class="flex-auto">
        <img src="../assets/emblem.png" width="65">
      </div>
      <div class="flex-none">
        <img src="../assets/ega.png" width="100">
      </div>
    </div>
    <!-- tabs -->
    <div>
      <div class="flex justify-center space-x-12">
        <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = tab" :class="{
          'text-yellow-400': activeTab === tab,
          'text-white': activeTab !== tab,
        }" class="mr-4 pb-2 cursor-pointer transition duration-300">
          {{ tab }}
        </button>
      </div>
      <div v-if="activeTab === 'Home'">
        <!-- Content for Home -->
        <div class="flex cols-2">
          <div>
            <WelcomeWords />
          </div>

          <div>
            <LoginPage />
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'About'">
        <!-- Content for About -->
        <div class="flex justify-center">
        <div class="grid grid-cols-2 gap-24 place-content-evenly mt-16 w-4/6">
          <div class="card bg-[#28409A] shadow-2xl shadow-blue-950 rounded-2xl p-10 h-64">
            <h1 class="card-header text-yellow-400 font-extrabold italic">
            Countering the Rise of Private Cryptocurrencies</h1>
            <div class="card-body text-white">
              CBDCs allow central banks to maintain control over the currency supply
              and payments system while competing with private digital currencies
            </div>
          </div>
          <div class="card bg-[#28409A] shadow-2xl shadow-blue-950 rounded-2xl p-10 h-64">
            <h1 class="card-header text-yellow-400 font-extrabold italic">
            Enhance Financial Inclusion</h1>
            <div class="card-body text-white">
              CBDCs can bridge this gap by providing a digital currency that can be accessed
              through basic smartphones, allowing people who are unbanked or underbanked to participate
              in the formal financial system
            </div>
          </div>
          <div class="card bg-[#28409A] shadow-2xl shadow-blue-950 rounded-2xl p-10 h-64">
            <h1 class="card-header text-yellow-400 font-extrabold italic">
            Strengthening Monetary Policy</h1>
            <div class="card-body text-white">
              By digitizing the currency, Central banks can have direct insights into the movement of 
              money, enabling them to fine-tune policies like interest rates and money supply in real-time.
              This can helpp control inflation, stabilize the economy, and respond more effectively to 
              economic crises
            </div>
          </div>
          <div class="card bg-[#28409A] shadow-2xl shadow-blue-950 rounded-2xl p-10 h-64">
            <h1 class="card-header text-yellow-400 font-extrabold italic">
            Modernizing the Monetary System</h1>
            <div class="card-body text-white">
              A CBDC serve as a modern update to the traditional monetary system. Just as paper money replaced
              barter evolution of national currency. It provides a secure, efficient, and technologically advanced 
              form of money that can keep pace with the rapidly digitizing world.
              CBDCs enable smoother, faster, and cheaper transactions, reducing the reliance on physical
              cash and outdated payment system
            </div>
          </div>
        </div>
        </div>
      </div>

      <div v-if="activeTab === 'Info'">
        <!-- Content for Info -->
        <div class="flex justify-center mt-10 h-[15cm]">
          <div class="card grid grid-cols-3 gap-4 place-content-center mt-10 w-5/6 rounded-2xl
         bg-[#28409A] shadow-2xl p-12 shadow-[#28409A] text-white">
            <div class="text-sm space-y-2">
              <h1 class="text-xl">Contacts</h1>
              <div class="grid md:grid-cols-0 md:gap-6 pt-5">
                <div class="relative z-0 w-full mb-6">
                  <label>Mobile: <span>0764292299 / 0763292299</span></label>
                </div>
              </div>
              <div class="grid md:grid-cols-0 md:gap-6 pt-2">
                <div class="relative z-0 w-full mb-6">
                  <label>Address: <span>S.L.P 2833, DODOMA</span></label>
                </div>
              </div>
              <div class="grid md:grid-cols-0 md:gap-6 pt-2">
                <div class="relative z-0 w-full mb-6">
                  <label>Email: <span>info@ega.go.tz / <br></span>
                    <span>barua@ega.go.tz</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <h1 class="text-xl">Other Links</h1>
              <div class="grid md:grid-cols-0 md:gap-6 pt-5">
                <div class="relative z-0 w-full mb-6">
                  <label>Alphachain Network</label>
                </div>
              </div>
              <div class="grid md:grid-cols-0 md:gap-6 pt-2">
                <div class="relative z-0 w-full mb-6">
                  <label class="focus:text-yellow-400">Explore</label>
                </div>
              </div>
              <div class="grid md:grid-cols-0 md:gap-6 pt-2">
                <div class="relative z-0 w-full mb-6">
                  <label class="focus:text-yellow-400">Faucet</label>
                </div>
              </div>
              <div class="grid md:grid-cols-0 md:gap-6 pt-2">
                <div class="relative z-0 w-full mb-6">
                  <label class="focus:text-yellow-400">dApps</label>
                </div>
              </div>
            </div>
            <div>
              <h1 class="text-xl">Join Our Community</h1>
              <div class="grid md:grid-cols-0 md:gap-6 pt-5">
                <div class="relative z-0 w-full mb-6">
                  <button type="button" class="bg-yellow-400 text-[#28409A] font-bold p-2 rounded-lg w-1/2">Join Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="bg-white absolute bottom-0 w-full text-center flex items-center justify-center p-1 rounded-lg">
      <span class="text-[#28409A]">
        Developed & Maintained by
      </span>
      <img src="../assets/ega.png" width="40" class="ml-2 cursor-pointer">
    </div>
  </div>
</template>

<script>
import LoginPage from '@/components/Loginpage.vue'
import WelcomeWords from '@/components/Welcomewords.vue'


export default {
  name: 'LandingVue',

  components: {
    LoginPage,
    WelcomeWords
  },
  data() {
    return {
      tabs: ['Home', 'About', 'Info'], // Add more tabs if needed
      activeTab: 'Home', // Default active tab
    };
  },
}

</script>

<style scoped>
.bg-color {
  background: linear-gradient(330deg, #020024, #28409a, #00d4ff);
  background-size: 600% 600%;

  -webkit-animation: bg-animation 18s ease infinite;
  -moz-animation: bg-animation 18s ease infinite;
  animation: bg-animation 18s ease infinite;
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 58% 0%
  }

  50% {
    background-position: 43% 100%
  }

  100% {
    background-position: 58% 0%
  }
}

@-moz-keyframes bg-animation {
  0% {
    background-position: 58% 0%
  }

  50% {
    background-position: 43% 100%
  }

  100% {
    background-position: 58% 0%
  }
}

@keyframes bg-animation {
  0% {
    background-position: 58% 0%
  }

  50% {
    background-position: 43% 100%
  }

  100% {
    background-position: 58% 0%
  }
}

@keyframes bg-animati {
  0% {
    background-position: 58% 0%
  }

  50% {
    background-position: 43% 100%
  }

  100% {
    background-position: 58% 0%
  }
}
</style>