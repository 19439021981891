<script>
export default {
   name: 'WelcomeWords',
}
</script>
<template>
    <div class="grid-rows-2 mr-[40%] px-10 p-[25%]">
        <div>
            <span class="text-white font-bold text-4xl">
            “Investing in People, Banking on Futures”
          </span>
        </div>
          <div class="">
            <span class="text-white font-bold">
            <p class="inline-block align-baseline">
            The Tanzanian Central Bank Digital Currency aims to bank the unbanked and increase the digital economy adoption to Instituions,
             Business and individual by introducting a digital native currency 
            </p>
          </span>
          </div>
    </div>
</template>


<style>

</style>