import { createApp } from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from '../src/router/index.js'
import '../src/index.css'

import store from './store'

import { apolloProvider } from "./vue-apollo";

let app = createApp(App)
app.use(Vuex)
app.use(router);
app.use(store)
app.use(apolloProvider)

app.config.productionTip = false
app.mount("#app")