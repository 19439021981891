import { gql } from 'apollo-boost'

export const UserLogin = gql`
mutation UserLogin($inputData: UserLoginType!) {
  login(
   inputData: $inputData) {
     user {
      id
      username
      password
      Role
     }
   }
}
`