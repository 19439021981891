<template>
  <div>
    <div class="px-24 py-[2.5cm]" id="blurEffect">
      <div class="col-2drop-shadow-xl 
       border-[#28409A]">

        <div class="col-3 rounded-xl glasscss drop-shadow-xl 
          border-slate-200 p-10 m-15">
          <div class="flex flex-col">
            <span class="text-white text-3xl font-extrabold flex justify-center">
              Welcome
            </span>
            <span class="text-white font-bold flex justify-center">
              Tanzanian Central Bank Digital Currency
            </span>
          </div>
          <div class="col-2 flex py-8">
            <div class="flex flex-col gap-2 w-full">
              <input id="Username" class="border border-slate-100 
                 focus:ring-0 focus:ring-slate-100 focus:border-0 
                 rounded-xl px-4 p-[2.5%] bg-white hover:bg-[#e5e5e5] 
                 drop-shadow-xl" type="text" placeholder="Username" v-model="username">
            </div>
          </div>
          <div class="col-2 flex py-1">
            <div class=" flex flex-col gap-2 justify-center w-full">
              <input id="password" class="border border-slate-100 
                 focus:ring-0 focus:ring-slate-100 focus:border-0 
                 rounded-xl px-4 p-[2.5%] bg-white hover:bg-[#e5e5e5] 
                 drop-shadow-xl" type="password" placeholder="Password" v-model="password">
            </div>
          </div>

          <div class="col-2 py-3">
            <div class="flex justify-center items-center">
              <div class="mt-3 items-center">
                <label class="items-center" for="Forget">
                  <a href="javascript::void()" class="hover:font-semibold text-white underline">
                    Forget Password?</a>
                </label>
              </div>
            </div>
          </div>

          <div class="cols-3">
            <div class="flex justify-center items-center mt-3">
              <button type="button" class="rounded-xl px-8 py-2 drop-shadow-md 
                   hover:bg-[#4866d1] bg-[#28409A] justify-center 
                   hover:drop-shadow-xl text-white hover:text-white" @click="LoginUser()">
                Login
              </button>
            </div>
              <span v-if="validationError" class="text-sm flex justify-center" :class="{ 'error-message': validationError, 'error-text-red': validationError }">
                {{ errorMessage }}</span>
            <div class="flex row-2 mt-4">
              <div class="ml-4">
                <span class="text-white hover:text-[#4866d1]">
                  Don’t have an account ?
                </span>
                <span class="text-white underline hover:text-[#4866d1]" @click="redirectToregister">
                  Sign Up
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg p-6 w-96 border shadow-lg shadow-green-900">
        <div class="flex justify-center">
          <h2 class="text-xl font-semibold">
            <i class="fa-regular fa-circle-check fa-2xl text-green-500"></i>
            Login Successful
          </h2>
        </div>
        <div class="mt-4 flex justify-center">
          <button @click="closeModal" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold
            py-2 px-4 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { apolloClient } from '@/vue-apollo'

import { UserLogin } from '../Graphql/Admin/login.graphql'
export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      isModalOpen: false,
      validationError: false,
      errorMessage: '',
    }
  },

  methods: {
    showSuccessAlert() {
      Swal.fire({
        title: 'Success!',
        text: 'Login successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
        timer: 2000, // 2 seconds
        timerProgressBar: true,
        // willClose: () => {
        //   router.push('/dashboard'); // Redirect to /dashboard after 2 seconds
        // }
      });
    },
    showErrorAlert() {
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.$router.push('/usermanage')
    },
    LoginUser() {
      let inputData = {
        username: this.username,
        password: this.password
      }

      apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: UserLogin,
        variables: {
          inputData: inputData
        }
      }).then(response => {
        console.log(response);
        let userRole = response?.data?.login?.user[0]?.Role
        // alert(userRole)
        // this.openModal()
        this.showSuccessAlert()
        localStorage.setItem('uname', this.username)
        localStorage.setItem('Role', userRole)
  
        if (userRole === 'Admin') {
          this.$router.push('/dashboard')
        } else if (userRole === 'Commercial') {
          this.$router.push('/commercial-bank')
        }else if (userRole === 'Institution') {
          this.$router.push('/institution')
        }else if (userRole === 'Business') {
          this.$router.push('/business')
        }
        else {
          this.showErrorAlert()
        }
      }).catch(err => {
        this.showErrorAlert()
        console.log(err)
      })
    },
    validation() {
      const jina = /^[a-zA-Z]+$/;
      // const pwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,16}$/;

      if (this.username == "") {
        this.validationError = true
        this.errorMessage = ""
        this.errorMessage = "Field username is empty!"
      }
      else if (this.password == "") {
        this.validationError = true
        this.errorMessage = ""
        this.errorMessage = "Field password is empty!"
      }
      else if (!jina.test(this.username)) {
        this.validationError = true
        this.errorMessage = ""
        this.errorMessage = "Only Alphabet at username input"
      }
      else {
        this.validationError = false
        this.errorMessage = " "
        this.LoginUser()
      }
    },
  },

}

</script>

<style>
.error-message {
  color: red;
}

.error-text-red {
  color: red;
}
.glasscss{
  background: rgba(255, 255, 255, 0.3);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
/* border: 9px solid rgba(128, 8, 8, 0.9) */
}
</style>