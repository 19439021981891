<template>
  <!-- give the sidebar z-50 class so its higher than the navbar if you want to see the logo -->
  <!-- you will need to add a little "X" button next to the logo in order to close it though -->
  <div class="w-1/2 md:w-1/3 lg:w-64 fixed md:top-0 md:left-0 h-screen lg:block
     bg-[#28409A] border-r z-30" :class="sideBarOpen ? '' : 'hidden'" id="main-nav">

    <div class="w-full h-20 flex px-24 items-center mb-4">
      <div class="flex-auto">
        <img src="../assets/emblem.png" width="65">
      </div>
    </div>
    <span class="flex justify-center text-md underline text-white">Admin Name</span>

    <div class="mt-12 px-4">
      <RouterLink to="/dashboard" class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <img src="../assets/dash24.png" alt="dashboard icon"><span class="text-white pl-3">
        Dashboard</span>
      </RouterLink>
      <RouterLink to="/usermanage" class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <img src="../assets/users32.png" alt=""><span class="text-white pl-3">
        User Management</span>
      </RouterLink>
      
      <button type='button' class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <a href="http://wallet.all.tz/">
          <i class="fa-solid fa-coins fa-xl text-white"></i>
          <span class="text-white pl-3">
          Minting</span>
        </a>
        </button>
        
      <!-- <RouterLink to="" class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <img src="../assets/users32.png" alt=""><span class="text-white pl-3">
        Institutions</span>
      </RouterLink>
      <RouterLink to="" class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <img src="../assets/users32.png" alt=""><span class="text-white pl-3">
        Business</span>
      </RouterLink>
      <RouterLink to="" class="w-full flex items-center mb-5 h-10 pl-4 border-b border-blue-800 focus:bg-[#FFC300] 
        rounded-lg cursor-pointer">
        <img src="../assets/users32.png" alt=""><span class="text-white pl-3">
        Individual</span>
      </RouterLink> -->
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/free-regular-svg-icons'
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/vue-fontawesome';
import { mapState } from 'vuex'

export default {
  name: 'SidebarVue',
  computed: {
    ...mapState(['sideBarOpen'])
  },
}
</script>