<template>
    <div>
        <div class="flex justify-end p-1 cursor-pointer font-semibold">
            <button class="bg-blue-800 mb-1 rounded-full w-8 h-8 font-bold" @click="openRegister">
                <i class="fa-solid fa-plus text-2xl text-white"></i>
            </button>
            <span class="pt-1 px-2">ADD</span>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-white uppercase bg-[#5F7CE2] dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            FirstName
                        </th>
                        <th scope="col" class="px-6 py-3">
                            LastName
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Email
                        </th>
                        <th scope="col" class="px-6 py-3">
                            UserName
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Date joined
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Last Login
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Staff
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Superuser
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Active
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Role
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Edit
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Delete
                        </th>
                    </tr>
                </thead>
                <tbody v-for="(item, id) in user" :key="id">
                    <tr class="bg-white border">
                        <!-- id captured -->
                        <td class="p-3 px-5 hidden">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.id }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.firstName }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.lastName }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="email" class="bg-transparent 
                                border-gray-300 py-2">{{ item.email }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.username }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.dateJoined }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.lastLogin }}</span>
                        </td>
                        <td class="p-3 px-5">
                            <input type="checkbox" v-model="item.isStaff" :disabled="true">
                        </td>
                        <td class="p-3 px-5">
                            <input type="checkbox" v-model="item.isSuperuser" :disabled="true">
                        </td>
                        <td class="p-3 px-5">
                            <input type="checkbox" v-model="item.isActive" :disabled="true">
                        </td>
                        <td class="p-3 px-5">
                            <span type="text" class="bg-transparent 
                                border-gray-300 py-2">{{ item.Role }}</span>
                        </td>
                        <td class="p-5 px-6">
                            <button type="button" @click="openModa2(item)">
                                <i class="fa fa-pen-to-square text-green-700 text-2xl"></i>
                            </button>
                        </td>
                        <td class="p-3 px-9">
                            <button type="button" @click="openModal">
                                <i class="fa fa-trash-can text-red-600 text-2xl"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- alert delete-->
    <div v-if="isModalOpen" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-slate-200 rounded-lg p-6 w-96 border shadow-md shadow-slate-100">
            <h2 class="text-xl font-semibold mb-2"><i class="fa-solid fa-circle-exclamation fa-2xl"
                    style="color: #f20202"></i>Alert </h2>
            <p>Do you Really want to Delete?</p>
            <div class="mt-4 flex justify-end">
                <button @click="closeModal"
                    class="bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded">
                    No
                </button>
                <button @click="confirmAction"
                    class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 ml-2 rounded">
                    Yes
                </button>
            </div>
        </div>
    </div>

    <!-- success delete -->
    <div v-if="isModalOpen1" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-6 w-96 border shadow-md shadow-green-100">
            <div class="flex justify-center">
                <h2 class="text-xl font-semibold">
                    <i class="fa-regular fa-circle-check fa-2xl text-green-500"></i>
                    Successful Deleted
                </h2>
            </div>
            <div class="mt-4 flex justify-center">
                <button @click="closeModal1" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold
                         py-2 px-4 rounded">
                    Ok
                </button>
            </div>
        </div>
    </div>

    <!-- success edit -->
    <div v-if="isModalOpen3" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-6 w-96 border shadow-md shadow-green-100">
            <div class="flex justify-center">
                <h2 class="text-xl font-semibold">
                    <i class="fa-regular fa-circle-check fa-2xl text-green-500"></i>
                    Edited Successfully
                </h2>
            </div>
            <div class="mt-4 flex justify-center">
                <button @click="closeModal3" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold
                         py-2 px-4 rounded">
                    Ok
                </button>
            </div>
        </div>
    </div>

    <!-- edit -->
    <div v-if="isModalOpen2" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-1 w-96 border shadow-md shadow-blue-100">
            <div class="flex justify-end">
                <button @click="closeModal2">
                    <i class="fa-solid fa-x"></i>
                </button>
            </div>
            <div class="px-6 py-1 lg:px-2">
                <form class="space-y-2">
                    <div>
                        <label for="first" class="block mb-2 text-sm font-medium text-gray-900
                         dark:text-white">First name</label>
                        <input type="text" v-model="userInput.firstName" class="bg-gray-50 border
                         border-gray-300 text-gray-900 text-sm rounded-lg
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white">
                    </div>
                    <div>
                        <label for="last" class="block mb-2 text-sm font-medium text-gray-900
                         dark:text-white">Last name</label>
                        <input type="text"  v-model="userInput.lastName" class="bg-gray-50 border
                         border-gray-300 text-gray-900 text-sm rounded-lg
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white">
                    </div>
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900
                         dark:text-white">Your email</label>
                        <input type="email" v-model="userInput.email" class="bg-gray-50 border
                         border-gray-300 text-gray-900 text-sm rounded-lg
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white" placeholder="name@company.com" readonly>
                    </div>
                    <div>
                        <label for="role" class="block mb-2 text-sm font-medium text-gray-900
                         dark:text-white">Role</label>
                         <select v-model="userInput.role" class="bg-gray-50 border
                         border-gray-300 text-gray-900 text-sm rounded-lg
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white">
                            <option value="" disabled selected>Choose Role</option>
                            <option value="Admin">Admin</option>
                            <option value="Commercial">Commercial</option>
                            <option value="Institution">Institution</option>
                            <option value="Business">Business</option>
                        </select>
                    </div>
                    <div>
                        <label for="username" class="block mb-2 text-sm font-medium text-gray-900
                         dark:text-white">User name</label>
                        <input type="text" v-model="userInput.username" class="bg-gray-50 border
                         border-gray-300 text-gray-900 text-sm rounded-lg
                          focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                           dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400
                            dark:text-white">
                    </div>
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="userInput.isActive" value="" class="sr-only peer">
                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1
                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer
                            dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
                            after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                            after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Active</span>
                    </label><br>
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="userInput.isStaff" value="" class="sr-only peer">
                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1
                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer
                            dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
                            after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                            after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Staff</span>
                    </label><br>
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="userInput.isSuperuser" value="" class="sr-only peer">
                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1
                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer
                            dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white 
                            after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white
                            after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                            after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Superuser</span>
                    </label>
                    <button type="submit" class="w-full text-white bg-blue-700
                        hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
                        font-medium rounded-lg text-md px-5 py-2.5 text-center dark:bg-blue-600
                        dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="submitAction">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
    <RegisterPage v-if="isRegisterOpen" @close="closeRegister"/>
    <!-- <div class="text-gray-900 bg-gray-200">
        <div class="p-4 flex">
            <h1 class="text-3xl">Users</h1>
        </div>
        <div class="px-3 py-4 flex justify-center">
            <table class="w-full text-md bg-white shadow-md rounded mb-4">
                <tbody>
                    <tr class="border-b">
                        <th class="text-left p-3 px-5">FirstName</th>
                        <th class="text-left p-3 px-5">LastName</th>
                        <th class="text-left p-3 px-5">Email</th>
                        <th class="text-left p-3 px-5">UserName</th>
                        <th class="text-left p-3 px-5">Roles</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    <tr class="border-b hover:bg-orange-100 bg-gray-100">
                        <td class="p-3 px-5">
                            <input type="text" value="user.fname" class="bg-transparent 
                            border-b-2 border-gray-300 py-2">
                        </td>
                        <td class="p-3 px-5">
                            <input type="text" value="user.lname" class="bg-transparent 
                            border-b-2 border-gray-300 py-2">
                        </td>
                        <td class="p-3 px-5">
                            <input type="text" value="user.email" class="bg-transparent 
                            border-b-2 border-gray-300 py-2">
                        </td>
                        <td class="p-3 px-5">
                            <input type="text" value="username" class="bg-transparent 
                            border-b-2 border-gray-300 py-2">
                        </td>
                        <td class="p-3 px-5">
                            <select value="user.role" class="bg-transparent border-b-2
                             border-gray-300 py-2">
                                <option value="isStaff">Staff</option>
                                <option value="isActive">Active</option>
                                <option value="isSuperuser">Superuser</option>
                            </select>
                        </td>
                        <td class="p-5 px-5 flex justify-evenly">
                            <button type="button">
                                <i class="fa fa-pen-to-square text-green-700 text-2xl"></i>
                            </button>
                        </td>
                        <td class="p-3 px-3">
                            <button type="button">
                                <i class="fa fa-trash-can text-red-600 text-2xl"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div> -->
</template>

<script>
import { apolloClient } from '@/vue-apollo';
import gql from 'graphql-tag';
import RegisterPage from '../../components/Registerpage.vue';
import {UpdateUser} from '../..//Graphql/Admin/update.graphql.js'
export default {
    name: 'UserManagement',
    data() {
        return {
            isModalOpen: false,
            isModalOpen1: false,
            isModalOpen2: false,
            isRegisterOpen: false,
            userInput: {
                firstName: '',
                lastName: '',
                email: '',
                username: '',
                role: '', //change to small letter
                isStaff: false,
                isSuperuser: false,
                isActive: false,
            },
            roles: '',
            user: [],
            capturedId: null,
            userDetails: [],
        };
    },
    // retrieve users
    async mounted() {
        this.FetchUser();
    },
    methods: {
        FetchUser(){
            let query = gql`{
                userCredentials{
                    id,
                    Role,
                    firstName,
                    lastName,
                    username,
                    password,
                    email,
                    lastLogin,
                    isStaff,
                    isSuperuser,
                    isActive,
                    dateJoined
                }
            }`;

            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: query
            }).then(async (response) => {
                // console.log(response)
                this.user = response.data.userCredentials
            }).catch(err => {
                console.log(err);
            })
        },
        //alert
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        //delete success
        openModal1() {
            this.isModalOpen1 = true;
        },
        closeModal1() {
            this.isModalOpen1 = false;
        },
        confirmAction() {
            // Perform the action (e.g., delete)
            // Close the modal
            this.closeModal();

            //open 
            this.openModal1();
        },
        //edit
        openModa2(item) {
            // this.userInput = {
            //     id: item.id,
            //     firstName: item.firstName,
            //     lastName: item.lastName,
            //     email: item.email,
            //     username: item.username,
            //     Role: item.Role,
            //     isStaff: item.isStaff,
            //     isSuperuser: item.isSuperuser,
            //     isActive: item.isActive,
            // } 
            this.userInput = item   
            this.userInput.role = item.Role
            delete this.userInput.Role
            delete this.userInput.id
            delete this.userInput.lastLogin
            delete this.userInput.dateJoined
            this.isModalOpen2 = true;
        },
        closeModal2() {
            this.isModalOpen2 = false;
        },
        async UpdateUser(){
            apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: UpdateUser,
                variables: {
                    inputData: this.userInput
                }
            }).then(async (response) => {
                // Filter the userCredentials array based on the capturedId
                const user = response.data.userCredentials.find(user => user.id === this.capturedId);
                console.log(user)
                this.FetchUser()
                if (user) {
                    // Assign the user details to a data property for display
                    this.userDetails = user;
                } else {
                    console.log("User not found");
                }
            }).catch(err => {
                console.log(err)
            })
        },
        submitAction() {
            //perform action
            this.UpdateUser()
            this.closeModal2();
        },
        openRegister(){
            this.isRegisterOpen = true
        },
        closeRegister(){
            this.isRegisterOpen = false
        }
    },
    components: {
        RegisterPage
    }
}
</script>

<style></style>