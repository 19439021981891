import { createRouter, createWebHistory } from "vue-router"
import LoginPage from '@/components/Loginpage.vue'
import RegisterPage from '../components/Registerpage.vue'
import LandingVue from '../landingpage/Landing.vue'
import WelcomeWords from '../components/Welcomewords.vue'
import AdminVue from '../views/admin/admin.vue'
import HomeVue from '../views/admin/Home.vue'
import UserManagement from '../views/admin/User.vue'
import CommercialBank from '../views/commercebank/commercebank.vue'
import HomeCommercial from '../views/commercebank/HomeCommercial.vue'
import BusinessVue from '../views/business/business.vue'
import HomeBusiness from '../views/business/HomeBusiness.vue'
import institutionVue from '../views/institution/institution.vue'
import HomeInstitution from '../views/institution/HomeInstitution.vue'

const routes = [
  {
    path: '/',
    name: 'LandingVue',
    component: LandingVue,
    props: true
  },
  {
    path: '/dashboard',
    name: 'AdminVue',
    component: AdminVue,
    children: [
      {
        path: '/dashboard',
        name: 'HomeVue',
        component: HomeVue
      },
      {
        path: '/usermanage',
        name: 'UserManagement',
        component: UserManagement
      }
    ]
  },
  {
    path: '/commercial-bank',
    name: 'CommercialBank',
    component: CommercialBank,
    children: [
      {
        path: '/commercial-bank',
        name: 'HomeCommercial',
        component: HomeCommercial
      },
    ]
  },
  {
    path: '/institution',
    name: 'institution',
    component: institutionVue,
    children: [
      {
        path: '/institution',
        name: 'HomeInstitution',
        component: HomeInstitution
      },
    ]
  },
  {
    path: '/business',
    name: 'business',
    component: BusinessVue,
    children: [
      {
        path: '/business',
        name: 'HomeBusiness',
        component: HomeBusiness
      },
    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    props: true
  },
  {
    path: '/signup',
    name: 'RegisterPage',
    component: RegisterPage,
    props: true
  },
  {
    path: '/words',
    name: 'WelcomeWords',
    component: WelcomeWords,  
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router