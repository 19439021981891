import { gql } from 'apollo-boost'

export const UpdateUser = gql`
mutation UpdateUser($inputData:UserCredentialType!){
  updateUser(
    userDetails: $inputData){
    user{
      id
      firstName
      lastName
      email
      username
      password
      isStaff
      isSuperuser
      lastLogin
      Role
      dateJoined
      isActive
    }
  }
}
`